<template>
  <el-form ref="mainForm" :model="form" label-width="120px">
    <el-form-item label="Students">
      <el-tag
        v-for="student in studentList"
        :key="student"
        class="mx-1"
        closable
        :disable-transitions="false"
        @close="handleDeleteTag(student)"
      >
        {{ student.studentId }}
      </el-tag>
      <el-input
        v-if="inputStudentIdVisible"
        ref="InputStudentRef"
        v-model="inputStudentId"
        class="studentIdInput"
        size="small"
        @keyup.enter="handleInputConfirm"
        @blur="handleInputConfirm"
      >
      </el-input>
      <el-button
        v-else
        class="button-new-tag ml-1"
        size="small"
        @click="showStudentIdInput"
      >
        + New Student
      </el-button>
      <div style="margin-top:5px;">
        <el-button type="primary" @click="openImportStudentDialog">
          <el-icon>
            <plus />
          </el-icon>
          Import From Students
        </el-button>
      </div>
    </el-form-item>
    <el-form-item prop="name" label="Point Type">
      <el-select
        v-model="form.title"
        value-key="name"
        placeholder="Point Type"
        class="mr10"
        :clearable="true"
        @change="handleSelectPointType"
        style="display:block;"
      >
        <el-option
          v-for="item in options.pointsTemplateList"
          :key="item"
          :label="item.title"
          :value="item"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item prop="points" label="Points">
      <el-input readonly v-model="form.pointsTemplateDes"></el-input>
    </el-form-item>
    <el-form-item prop="comment" label="Comment">
      <el-input type="textarea" v-model="form.comment"></el-input>
    </el-form-item>
  </el-form>
  <div class="el-dialog__footer">
    <el-button type="primary" @click="issuePoints">
      Issue
    </el-button>
  </div>
  <el-dialog
    :title="dialogTitle"
    v-model="showStudentSelectionDialog"
    width="85%"
  >
    <students-selection :initData="{ parent: this, confirmFunction: handleImportStudents }" />
  </el-dialog>
</template>
<script>
import { getData, postData } from "../../service/api";
import StudentsSelection from "./StudentsSelection.vue";

export default {
  name: "addPoints",
  components: { StudentsSelection },
  props: {
    initData: {
      student: Object,
      callback: Function,
    },
  },
  data() {
    return {
      controllerUrl: "/points",
      options: {
        pointsTemplateList: [],
      },
      issuedCallback: null,
      dialogTitle: "Issue Points",
      showStudentSelectionDialog: false,
      inputStudentIdVisible: false,
      inputStudentId: "",
      studentList: [],
      form: {},
    };
  },
  watch: {
    "initData.student"() {
      this.initAndLoadData();
    },
  },
  created() {
    this.options.pointsTemplateList = this.$appSetting.globalConfig.pointsTemplateList;

    this.initAndLoadData();
  },
  methods: {
    initAndLoadData() {
      console.log("initAndLoadData");
      this.issuedCallback = this.initData.callback;
      let student = this.initData.student;
      if (student) {
        this.studentList.push(student);
      }
    },
    async loadStudentData(studentId) {
      if (studentId && studentId.length === 8) {
        let res = await getData(`/student/${studentId}`, null);
        if (res.result && res.code === "200") {
          return res.result;
        }
      }
      return null;
    },
    openImportStudentDialog() {
      this.showStudentSelectionDialog = true;
    },
    closeImportStudentDialog() {
      this.showStudentSelectionDialog = false;
    },
    handleImportStudents(studentList) {
      try {
        if (studentList && studentList.length > 0) {
          let studentList2 = this.studentList;
          for (let i = 0; i < studentList.length; i++) {
            let student = studentList[i];
            if (
              studentList2.findIndex((s) => s.studentId === student.studentId) <
              0
            ) {
              studentList2.push(student);
            }
          }
          this.studentList = studentList2;
          this.closeImportStudentDialog();
        }
      } catch {
        // ignore
      }
    },
    issuePoints() {
      let dataList = [];
      for (let i = 0; i < this.studentList.length; i++) {
        let student = this.studentList[i];
        dataList.push({
          studentId: student.studentId,
          studentName: student.username,
          name: this.form.name,
          title: this.form.title,
          points: this.form.points,
          issuedType: this.form.issuedType,
          comment: this.form.comment,
        });
      }
      postData(`${this.controllerUrl}/issuePoints`, { list: dataList }).then(
        (res) => {
          if (res.result && res.code === "200") {
            if (this.issuedCallback) {
              this.issuedCallback(res);
            }
          } else {
            this.$message.error(
              "Issue points failed, error message: " + res.message
            );
          }
        }
      );
    },
    handleSelectPointType(pointsTemplate) {
      this.form.name = pointsTemplate.name;
      this.form.title = pointsTemplate.title;
      this.form.points = pointsTemplate.points;
      this.form.issuedType = pointsTemplate.issuedType;
      this.form.pointsTemplateDes =
        pointsTemplate.issuedType === "Normal"
          ? `${pointsTemplate.points}`
          : `${pointsTemplate.points} (${pointsTemplate.issuedType})`;
    },
    handleDeleteTag(student) {
      let index = this.studentList.findIndex(
        (s) => s.studentId === student.studentId
      );
      if (index >= 0) {
        this.studentList.splice(index, 1);
      }
    },
    async handleInputConfirm() {
      if (this.inputStudentId) {
        let student = await this.loadStudentData(this.inputStudentId);
        if (
          student &&
          this.studentList.findIndex((s) => s.studentId === student.studentId) <
            0
        ) {
          this.studentList.push(student);
        } else {
          this.$message.console.warn("Cannot find the student.");
        }
      }
      this.inputStudentIdVisible = false;
      this.inputStudentId = "";
    },
    showStudentIdInput() {
      this.inputStudentIdVisible = true;
      this.$nextTick(() => {
        this.$refs.InputStudentRef.focus();
      });
    },
  },
};
</script>
<style scoped>
.studentIdInput {
  width: 100px;
}
.el-tag {
  margin-right: 5px;
}
</style>
